import Vue from "vue";
import Router from "vue-router";
import store from "./store";

// VISTAS
import Home from "@/views/Home";
// HOW WE ARE
import About from "@/views/howWeAre/About";
import Governance from "@/views/howWeAre/Governance";
import WorkWithUs from "@/views/howWeAre/WorkWithUs";
import Responsability from "@/views/howWeAre/Responsability";
import Transparency from "@/views/howWeAre/Transparency";

// PRODUCTS
import Product from "@/views/Product";
import NewProduct from "@/components/products/NewProduct";
import ListProducts from "@/components/products/ListProducts";
import EditProduct from "@/components/products/EditProduct";

// SIMULATORS
import SimulatorCredit from "@/views/simulators/Credit";
import SimulatorInversion from "@/views/simulators/Inversion";

// SERVICES
import Convenios from "@/views/servicios/Convenios";
import Payments from "@/views/servicios/Payments";

import Contacts from "@/views/Contacts";
import Login from "@/views/Login";
import Usuario from "@/views/users/User";
// import Error from '@/views/Error'
import Inquiries from "@/views/inquiries/inquiries";
//provedores
import Proveedores from "@/views/proveedores/proveedores";
//Concursos
import Concursos from "@/views/concursos/concursos";
//Concursos
import AppMovil from "@/views/appmovils/appmovil";
import Educafinan from "@/views/appmovils/educafinan";
//Quejas y reclamos
import Quejas from "@/views/quejas/quejas";

import TerminosApp from "@/views/terminosCondiciones/termminosApp";

import PoliticasSegurida from "@/views/terminosCondiciones/politicasseguridad";
//PARTE DE FORMULARIOS
import CreditRequest from "@/views/howWeAre/CreditRequest";
import RequestInvestment from "@/views/howWeAre/RequestInvestment";
//validator
import Validator from "@/views/validator/validator";

//deleteacount
import Deleteacount from "@/views/deleteacount/deleteacount";

Vue.use(Router);

// export default new Router({
var router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        libre: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        libre: true
      }
    },
    {
      path: "/nosotros",
      name: "nosotros",
      component: About,
      meta: {
        libre: true
      }
    },
    {
      path: "/trabaja-con-nosotros",
      name: "trabaja",
      component: WorkWithUs,
      meta: {
        libre: true
      }
    },
    {
      path: "/gobernanza",
      name: "gobernanza",
      component: Governance,
      meta: {
        libre: true
      }
    },
    {
      path: "/responsabilidad-social",
      name: "responsabilidad",
      component: Responsability,
      meta: {
        libre: true
      }
    },
    {
      path: "/transparencia",
      name: "transparencia",
      component: Transparency,
      meta: {
        libre: true
      }
    },
    {
      path: "/terminos-y-condiciones",
      name: "terminos",
      component: TerminosApp,
      meta: {
        libre: true
      }
    },
    {
      path: "/politicas-seguridad",
      name: "politicas",
      component: PoliticasSegurida,
      meta: {
        libre: true
      }
    },

    // SERVICES
    {
      path: "/convenios",
      name: "convenios",
      component: Convenios,
      meta: {
        libre: true
      }
    },
    {
      path: "/pagos",
      name: "pagos",
      component: Payments,
      meta: {
        libre: true
      }
    },

    // PRODUCTS
    {
      path: "/producto/:id",
      name: "producto",
      component: Product,
      meta: {
        libre: true
      }
    },
    {
      path: "/nuevo-producto",
      name: "nuevo-producto",
      component: NewProduct,
      meta: {
        admin: true
      }
    },
    {
      path: "/lista-productos",
      name: "lista-productos",
      component: ListProducts,
      meta: {
        libre: true
      }
    },
    {
      path: "/editar-producto/:id",
      name: "editar-productos",
      component: EditProduct,
      meta: {
        libre: true
      }
    },

    // SIMULATORS
    {
      path: "/simulador-credito",
      name: "simulador-credito",
      component: SimulatorCredit,
      meta: {
        libre: true
      }
    },
    {
      path: "/simulador-inversiones",
      name: "simulador-inversiones",
      component: SimulatorInversion,
      meta: {
        libre: true
      }
    },
    {
      path: "/usuario",
      name: "usuario",
      component: Usuario,
      meta: {
        admin: true
      }
    },
    {
      path: "/contactos",
      name: "contactos",
      component: Contacts,
      meta: {
        libre: true
      }
    },
    {
      path: "/inquiries",
      name: "inquiries",
      component: Inquiries,
      meta: {
        libre: true
      }
    },
    //validator
    {
      path: "/validator",
      name: "validator",
      component: Validator,
      meta: {
        libre: true
      }
    },
    //delete_acount
    {
      path: "/deleteacount",
      name: "deleteacount",
      component: Deleteacount,
      meta: {
        libre: true
      }
    },
    //PROVEEDORES
    {
      path: "/formularios-proveedores",
      name: "proveedores",
      component: Proveedores,
      meta: {
        libre: true
      }
    },
    {
      path: "/concursos",
      name: "concursos",
      component: Concursos,
      meta: {
        libre: true
      }
    },
    //Quejas
    {
      path: "/quejas",
      name: "quejas",
      component: Quejas,
      meta: {
        libre: true
      }
    },
    {
      path: "/appmovil",
      name: "appmovil",
      component: AppMovil,
      meta: {
        libre: true
      }
    },
    {
      path: "/educafinan",
      name: "educafinan",
      component: Educafinan,
      meta: {
        libre: true
      }
    },
    {
      path: "/formulario-credito",
      name: "formulario-credito",
      component: CreditRequest,
      meta: {
        libre: true
      }
    },
    {
      path: "/formulario-inversiones",
      name: "formulario-inversiones",
      component: RequestInvestment,
      meta: {
        libre: true
      }
    }
    // {
    //   path: '/coin/:id',
    //   name: 'coin-detail',
    //   component: CoinDetail
    // },

    // {
    //   path: '*',
    //   name: 'error',
    //   component: Error
    // }
  ]
});

// ROUTES CONTROL
router.beforeEach((to, from, next) => {
  // console.log("router", to)
  // console.log("store", store.state)
  if (to.matched.some(record => record.meta.libre)) {
    next();
  } else if (store.state.user && store.state.user.type == "1") {
    console.log("contenido store", store);
    if (to.matched.some(record => record.meta.admin)) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});
export default router;
