<template>
  <!-- DROPBOX -->
  <div class="relative" @mouseover="showDrop" @mouseleave="hideDrop">
    <button
      @click="toggleMenu"
      class="block mt-4 lg:inline-block lg:mt-0 text-gray-800  hover:text-gray-700  mr-4"
    >
      Quienes Somos
    </button>
    <div class="absolute w-full">&nbsp;</div>
    <div
      v-if="open"
      class="absolute w-44 bg-white border-b z-10 p-3  rounded shadow ml-0 md:-ml-10 "
    >
      <router-link
        to="/nosotros"
        class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Nosotros</router-link
      >
      <router-link
        to="/gobernanza"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Gobernanza</router-link
      >
      <router-link
        to="/responsabilidad-social"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Responsabilidad Social</router-link
      >
      <router-link
        to="/trabaja-con-nosotros"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Trabaja con Nosotros</router-link
      >
      <router-link
        to="/transparencia"
        class="text-sm py-2 px-1  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
        >Transparencia</router-link
      >
      <div class="relative" @mouseover="showDrop" @mouseleave="hideDrop">
        <button
          @click="toggleMenu"
          class="block mt-4 lg:inline-block lg:mt-0 text-gray-800  hover:text-gray-700  mr-4"
        >
          Politicas
        </button>
        <div class="absolute w-full">&nbsp;</div>
        <div
          v-if="open"
          class="absolute w-44 bg-white border-b z-10 p-3  rounded shadow ml-0 md:-ml-10 "
          style="margin-left: 40% !important;"
        >
          <router-link
            to="/politicas-seguridad"
            class="text-sm py-2  px-9 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
            >Protección De Datos</router-link
          >

          <!--<router-link
          to="/transparencia"
          class="text-sm py-2 px-9  font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-red-600 hover:text-white "
          >Protección De Seguridad</router-link
        >-->
        </div>
      </div>
      <!--fin de los inventos de menu ue quiero hacer -->
    </div>
    <!--tratando de mover un menu dentro de otro-->
  </div>
  <!-- DROPBOX END -->
</template>

<script>
export default {
  name: "QuienesSomos",
  data() {
    return {
      open: false
    };
  },
  methods: {
    showDrop() {
      this.open = true;
    },
    hideDrop() {
      this.open = false;
    },
    toggleMenu() {
      this.closed = !this.open;
    },
    showDrop2() {
      this.open = true;
    },
    hideDrop2() {
      this.open = false;
    },
    toggleMenu2() {
      this.open = !this.closed;
    }
  }
};
</script>
